@font-face {
  font-family: "DM Sans";
  src: url("assets/fonts/DM_Sans/DMSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DM Sans";
  src: url("assets/fonts/DM_Sans/DMSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter/static/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "DM Sans", sans-serif;
  color: var(--white);
  --circle-btn-size: 2.5rem;
  --font-size: 16px;
}

h4 {
  font-size: calc(1.275*var(--font-size) + 0.3vw);
}

h5 {
  font-size: calc(1.25*var(--font-size));
}

span {
  font-size: var(--font-size);
}

small {
  font-size: calc(0.8*var(--font-size));
}

p {
  font-size: var(--font-size);
}

.bg-watermark {
  &::before {
    content: "";
    background-image: url(./assets/Group71292x.png);
    background-position: center center;
    background-repeat: repeat;
    background-size: 70%;
    mix-blend-mode: overlay;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.accent {
  border-bottom-width: 4px !important;
  border-bottom-style: solid !important;
}

.workspace {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  top-bar {
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: var(--purple);

    content {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 3rem;
      min-height: 3rem;
    }

    footer {
      margin-top: 0.5rem;
      overflow-x: auto;
      .buttons {
        display: flex;
        gap: 0.5rem;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.btn-back {
  position: absolute;
  left: 1rem;
  top: var(--top-bar-padding-top);
}

.btn-back-size {
  height: var(--circle-btn-size);
  width: var(--circle-btn-size);
}

//fonts
.xsmall {
  font-size: calc(0.7*var(--font-size));
}

.small {
  font-size: calc(0.8*var(--font-size));
}

.medium {
  font-size: calc(0.9*var(--font-size))
}

//icons
.xl-icon svg {
  transform: scale(3);
}

.lg-icon, .lg-icon svg{
  transform: scale(1.5);
}

.md-icon, .md-icon svg {
  transform: scale(1.3);

  &:hover {
    transform: scale(1.5);
  }
}

.sm-icon {
  transform: scale(0.8);

  &:hover {
    transform: scale(0.9);
  }
}

.icon-bg {
  position: relative;
  right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.3rem;
  width: 2.3rem;
  border-radius: 50%;
}

.icon-sm {
  scale: 0.8;
}
.icon-sm-2 svg {
  scale: 0.8;
}

.icon-lg {
  transform: scale(1.2);
}

.instruction-icon {
  svg {
    path, rect {
      stroke: var(--white) !important
    }
  }
}
.icon-filled {
  fill: var(--white) !important
}

hr {
  color: var(--purple);
  height: 2px !important;
}

.rounded-start-3 {
  border-bottom-left-radius: 1.8rem !important;
  border-top-left-radius: 1.8rem !important;
}

.rounded-end-3 {
  border-bottom-right-radius: 1.8rem !important;
  border-top-right-radius: 1.8rem !important;
}

// accent colors
.stg-1 {
  border-bottom-color: #e02b33 !important;
}
.circle-bg-1 .circle {
  stroke: #e02b33;
}

.stg-2 {
  border-bottom-color: #970e33 !important;
}
.circle-bg-2 .circle {
  stroke: #970e33;
}

.stg-3 {
  border-bottom-color: #faa728 !important;
}
.circle-bg-3 .circle {
  stroke: #faa728;
}

.stg-4 {
  border-bottom-color: #f14821 !important;
}
.circle-bg-4 .circle {
  stroke: #f14821;
}

.stg-5 {
  border-bottom-color: #b8c40c !important;
}
.circle-bg-5 .circle {
  stroke: #b8c40c;
}

.stg-6 {
  border-bottom-color: #378635 !important;
}
.circle-bg-6 .circle {
  stroke: #378635;
}

.stg-7 {
  border-bottom-color: #6da4da !important;
}
.circle-bg-7 .circle {
  stroke: #6da4da;
}

.stg-8 {
  border-bottom-color: #37549a !important;
}
.circle-bg-8 .circle {
  stroke: #37549a;
}

.stg-9 {
  border-bottom-color: #ce81c7 !important;
}
.circle-bg-9 .circle {
  stroke: #ce81c7;
}

.stg-10 {
  border-bottom-color: #8c3f8d !important;
}
.circle-bg-10 .circle {
  stroke: #8c3f8d;
}

.stg-11 {
  border-bottom-color: #c0bd90 !important;
}
.circle-bg-11 .circle {
  stroke: #c0bd90;
}

.stg-12 {
  border-bottom-color: #635a6d !important;
}
.circle-bg-12 .circle {
  stroke: #635a6d;
}

.accent-info {
  border-bottom-color: var(--accent-info) !important;
}

//card

.card {
  border: none;
  border-bottom: solid 0.5rem var(--card-border-color);
  background: linear-gradient(
    242deg,
    var(--bg-navy-1) 0%,
    var(--bg-navy-2) 100%
  );
  border-radius: 0.5rem;
}
.card-header {
  padding: 1.25rem 2rem;
  border-bottom: solid 1px var(--input);
  background-color: transparent;
}
.card-body {
  padding: 1.25rem 2rem;
}

.card-footer {
  background-color: transparent !important;
}

//tables in lesson-planer
thead,
tbody,
tfoot,
tr,
td {
  border-color: transparent !important;
  border-style: none !important;
  border-width: 0;
}

.table {
  color: inherit;
}

.table-row {
  & > th,
  > td {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    &:first-child {
      border-bottom-left-radius: 0.5rem !important;
      border-top-left-radius: 0.5rem !important;
      padding-left: 1.7rem;
    }
    &:last-child {
      border-bottom-right-radius: 0.5rem !important;
      border-top-right-radius: 0.5rem !important;
      padding-right: 1.7rem;
    }
  }
}

.table-row-hover {
  & > td {
    cursor: pointer;
  }

  &:hover > td {
    opacity: 0.7;
  }
}

.table-row-hover-pointer {
  & > td {
    cursor: pointer;
  }
}

.table-row-head {
  & > th {
    background-color: var(--input) !important;
    vertical-align: middle;
  }
}

.table-body {
  & > tr:nth-child(even) {
    & > td {
      background-color: #78b6fd2e;
    }
  }
}

//lesson status
.lesson-status.booked {
  background-color: #95d566;
}
.lesson-status.due {
  background-color: #529f19;
}
.lesson-status.in-progress {
  background-color: #84c3ff;
}


.lesson-status.cancel {
  background-color: #dadada;
}

.lesson-status.complete {
  background-color: #076fd0;
}
.lesson-status.student-invalidate {
  background-color: #9e9e9e;
}
.lesson-status.teacher-invalidate {
  background-color: #484848;
}


.refresh {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-shadow: 0 0 5px 1px var(--secondary-hover);
  z-index: 10;
}

.avatar {
  background-position: center !important;
  background-size: cover !important;
}

.spinner {
  position: absolute;
  top: -10px;
}

.logo {
  max-width: 7rem;
  margin-bottom: 0.7rem;
}

.logo-sm {
  max-width: 6rem;
}

.logo-lg {
  max-width: 8rem;
}

//initials circle
.initials-circle {
  min-width: 3rem;
  width: 3rem;
  height: 3rem;
}
.initials-circle-lg {
  min-width: 4rem;
  width: 4rem;
  height: 4rem;
}

.profile-photo {
  background-size: cover;
  background-position: center;
}

// courses banners
.course {
  background: linear-gradient(
      180deg,
      #99c7fab5 0%,
      rgb(9, 20, 33, var(--course-bg-opacity)) 100%
    )
    0 0 no-repeat padding-box;
  border-radius: 0.5rem;
  padding: 2rem;
}

.course > * {
  position: relative;
}
.course-en,
.course-sp,
.course-enbus {
  position: relative;
  border-radius: 0.5rem;
}
.course-en::before,
.course-sp::before,
.course-enbus::before {
  content: "";
  position: absolute;
  border-radius: 0.5rem;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.course-en::before {
  background-image: url("https://assets.callan.app/callanonline/uk-flag.png");
  background-position-y: center;
}
.course-sp::before {
  background-image: url("https://assets.callan.app/callanonline/spain-flag.png");
  background-position-y: 30%;
}
.course-enbus::before {
  background-image: url("https://assets.callan.app/callanonline/be-bg.png");
  background-position-y: center;
}

.book-product {
  width: 9rem;
}

// navigation tabs
.nav-tabs {
  border-bottom: 2px solid var(--secondary) !important;
  .nav-link {
    color: var(--white);
    &:hover {
      border-color: transparent !important;
    }
  }
  .active, .active:hover {
    position: relative;
    border-color: transparent !important;
    background-color: transparent !important;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: var(--blue);
      bottom: -2px;
      right: 0;
      left: 0;
    }
  }
  .active, .nav-link:hover {
    color: var(--blue) !important;
  }
}
//nav-pills
.nav-pills-overflow {
  overflow-x: scroll;
  flex-wrap: nowrap;
}

.nav-pills .nav-link {
  background-color: var(--btn-pill-bg);
  color: var(--btn-pill-font);
  white-space: nowrap;
  &.active {
    background-color: var(--blue);
    color: #ffffff;
  }
  &:hover {
    background-color: var(--blue);
    color: #ffffff;
  }
}

//progress circle chart in exercise set host
.prev .single-chart {
  transform: translateX(-50%) scale(0.5) translateX(-170%) !important;
  left: 50%;

}

.left .single-chart {
  transform: translateX(-50%) scale(0) translateX(-170%) !important;
  left: 50%;

}

.selected .single-chart {
  transform: scale(1) translateX(-50%)  !important;
  left: 50%;

}

.next .single-chart {
  transform: translateX(-50%) scale(0.5) translateX(170%) !important;
  left: 50%;

}

.right .single-chart {
  transform: translateX(-50%) scale(0) translateX(170%) !important;
  left: 50%;

}

//list group
.list-group .list-group-item {
  border-radius: .25rem;
  margin-bottom: .25rem;
  border-color: var(--blue) !important;
  background-color: var(--blue) !important;
  color: #f8ffff !important;
  &:hover{
    cursor: pointer;
    border-color: var(--blue-hover) !important;
    background-color: var(--blue-hover) !important;
  }
  &.active{
    border-color: #5ba8ff !important;
    background-color: #5ba8ff !important;
  }

}

.cursor-pointer {
  cursor: pointer;
}

.rounded-start-3 {
  border-bottom-left-radius: 1.8rem !important;
  border-top-left-radius: 1.8rem !important;
}

.rounded-end-3 {
  border-bottom-right-radius: 1.8rem !important;
  border-top-right-radius: 1.8rem !important;
}

/* Chrome, Safari, Edge, Opera */
.input-arrows-none::-webkit-outer-spin-button,
.input-arrows-none::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-arrows-none[type=number] {
  -moz-appearance: textfield;
}

.textarea-height {
  height: 8rem !important;
}
.z-index-10 {
   z-index: 10 !important;
}

.nav-pills {
  gap: .5rem;
  .nav-link {
    border-radius: 50rem;
    font-size: .875rem;
    background-color: var(--secondary);
    color: #FFFFFF;
    &.active {
      background-color: var(--blue)
    }
  }
}

//dashboard layout
.nav-container {
  position: absolute;
  top: var(--top-bar-padding-top);
  right: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  z-index: 100;
  .dropdown-menu {
    background-color: var(--bg-1);
    box-shadow: 0 0 10px #0f7ef886;
    padding: 0.5rem;
    border-radius: 0.5rem;
    width: 12rem;
    top: 3.2rem;
    &::after {
      //this little triangle on top
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border: 20px solid transparent;
      border-bottom-color: var(--bg-1);
      border-top: 0;
    }
    .dropdown-item {
      padding: 0.5rem;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      height: 3rem;
      font-size: 0.8rem;
      cursor: pointer;
      app-icon {
        fill: var(--blue);
        margin-right: 1rem;
      }
      &:hover {
        background-color: var(--purple);
        color: var(--white);
      }
    }
  }
  .dropdown-profile {
    left: -4.4rem;
    &::after {
      top: -1rem;
      left: 50%;
      margin-left: -20px;
      margin-bottom: 120px;
    }
  }
  .dropdown-notifications {
    left: -7.4rem;
    &::after {
      top: -1rem;
      left: 83%;
      margin-left: -20px;
      margin-bottom: 120px;
    }
  }
}
.btn-circle {
  border-radius: 50%;
  width: var(--circle-btn-size);
  height: var(--circle-btn-size);
}

.removed {
  color: #ff4814;
  text-decoration: line-through;
}

.added {
  color: var(--blue) !important;
}

.correction-changes-bg {
  background-color: rgba(0, 0, 0, 0.5);
}

.light-theme .correction-changes-bg {
  background-color: rgba(133, 169, 213, 0.47);
}

.accordion-item {
  background-color: #214875 !important;
  border-radius: .5em !important;
  border: 0 !important;
  .accordion-button {
    color: inherit !important;
    box-shadow: none !important;
    background-color: #093D78 !important;
    border-radius: .5em !important;
    :focus {
      outline: none;
      color: inherit;
    }
  }
}

.accordion-body {
  .accordion-button {
    font-size: .9rem;
    background-color: #14345a !important;
  }
  .accordion-item {
    border: 0;
    background-color: #214875 !important;
    border-radius: .5em !important;
  }
}

.light-theme {
  .accordion-item {
    background-color: #f9f9f9 !important;
    .accordion-button {
      background-color: #dfeeff !important;
    }
  }
  .accordion-body {
    .accordion-button {
      background-color: #dfdfdf !important;
    }
    .accordion-item {
      background-color: #f9f9f9 !important;
    }
  }
}

//navbar
.navbar-panel {
  position: fixed;
  top: 0;
  left: 0;
  bottom: auto;
  width: 12rem;
  height: 100%;
  border-right: 1rem solid var(--purple);
  padding: 2.5rem 0 0 .5rem;
}
.offcanvas-menu {
  background: var(--bg-1);
  padding: 1rem;
}

.navbar-nav {
  .nav-item {
    display: flex;
    align-items: center;
    border-radius: 0.25rem;
    padding: 0.35rem 1rem;
    margin: 0.2rem 0;
    width: 100%;
    cursor: pointer;
    .nav-link {
      font-size: 0.8rem;
      padding-inline: 0;
      word-break: break-word;
    }
    app-icon {
      fill: var(--blue);
      position: relative;
      right: 0.7rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2.3rem;
      width: 2.3rem;
      min-width: 2.3rem;
      border-radius: 50%;
    }
  }
  .active {
    background-color: var(--purple);
    .nav-link {
      color: var(--white);
    }
    app-icon {
      fill: var(--white) !important;
    }
  }
  .divider {
    height: 1px;
    color: var(--purple);
  }
  .navbar-brand {
    position: relative;
    z-index: 20;
    top: 0;
  }
  .btn-circle {
    width: var(--circle-btn-size);
    height: var(--circle-btn-size);
  }

  @media (min-width: 768px) {
    .nav-item {
      border-radius: 3.5rem 0 0 3.5rem;
    }
    .active {
      app-icon {
        background-color: var(--blue);
      }
    }
  }

}

@media only screen and (min-width: 576px) {
  .nav-container {
    top: 2.5rem;
    right: 1.5rem;
  }
  .offcanvas-menu {
    padding: 2.5rem 1.5rem;
  }

  .fs-sm-2 {
    font-size: calc(1.325rem + 0.9vw) !important;;
  }
}
@media only screen and (min-width: 768px) {
  .nav-container {
    top: 2rem;
    right: 2rem;
  }
}
@media only screen and (min-width: 992px) {
  .nav-container {
    padding-right: 2rem;
  }
}

// ULTRA SMALL
@media only screen and (max-width: 576px) {
  .workspace top-bar content {
    font-size: calc(0.8*var(--font-size));
  }
  h3 {
    font-size: calc(1.3*var(--font-size));
  }
  h4,
  .h4 {
    font-size: calc(1.275*var(--font-size))!important;
    margin-bottom: 0.125rem !important;
  }
  h5 {
    font-size: calc(1.2*var(--font-size));
  }
}
@media only screen and (min-width: 576px) {
  body {
    --circle-btn-size: 3rem;
  }
  .btn-back {
    left: 1.5rem;
    top: 2.5rem;
  }
}
@media only screen and (min-width: 768px) {
  .bg-watermark {
    &::before {
      bottom: 0;
      left: 12rem;
    }
  }

  .workspace {
    margin-right: 0;
    margin-left: 12rem;
    width: calc(100% - 12rem);
    height: 100%;
    min-height: 100vh;

    top-bar {
      content {
        text-align: center;
        padding: 0 0 0 3rem;

        p {
          max-width: 30rem;
        }
      }
      footer {
        padding: 0.75rem 3rem 0.3rem 3rem;
        border-top: 2px solid var(--purple);

        .buttons {
          gap: 1rem;
        }
      }
    }
  }

  .btn-back {
    position: absolute;
    left: 15rem;
    top: 2rem;
  }

  .refresh {
    bottom: 2rem;
  }

  .spinner {
    position: absolute;
    top: -2px;
  }

  .border-md-right {
    border-right: 1px solid;
  }
  .nav-pills-overflow {
    overflow-x: auto;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 992px) {
  .workspace {
    top-bar {
      content {
        text-align: center;
        min-height: 3rem;

        p {
          padding-left: 3rem;
        }
      }

      footer {
        padding-left: 6rem;
      }
    }
  }

  .btn-back {
    display: flex;
    padding-right: 1.5rem;
    padding-left: 1rem;
    width: fit-content;
    align-items: center;
    left: 18rem;

    span {
      height: fit-content;
    }
  }

  .border-lg-right {
    border-right: 1px solid;
  }
}

.fs-2{
  font-size: calc(1.325*var(--font-size) + 0.3vw)!important;
}

.fs-3{
  font-size: calc(1.3*var(--font-size) + 0.3vw)!important;
}

.fs-4{
  font-size: calc(1.275*var(--font-size) + 0.3vw)!important;
}

.fs-5{
  font-size: calc(1.25*var(--font-size))!important;
}

.fs-6 {
  font-size: var(--font-size) !important;
}

.popover {
  border-color: var(--blue)
}

.bs-popover-bottom {
  .popover-arrow::before {
    border-bottom-color: var(--blue)
  }
  .popover-arrow::after{
    top: 1.3px;
  }
}
.bs-popover-top {
  .popover-arrow::before {
  border-top-color: var(--blue)
  }
  .popover-arrow::after{
    bottom: 1.3px;
  }
}
.bs-popover-end {
  .popover-arrow::before {
  border-right-color: var(--blue);
  }
  .popover-arrow::after {
    left: 1.3px;
  }
}
.bs-popover-start {
  .popover-arrow::before {
  border-left-color: var(--blue)
  }
  .popover-arrow::after{
    right: 1.3px;
  }
}
