body { //web
  --top-bar-padding-top: 2rem;
}

body.emulated {
  --top-bar-padding-top: 2.5rem;
  input, textarea {
    caret-color: var(--white) !important;
  }
  .ios-correction-margin {
    margin-bottom: 5rem !important;
  }
}

body.android {
  --top-bar-padding-top: 3rem;
  .bottom-bar-padding {
    padding-bottom: 1rem;
  }
  .offcanvas-body {
    padding-top: 3rem !important;
  }
}

body.ios {
  --top-bar-padding-top: 3rem;
  .bottom-bar-padding {
    padding-bottom: 1.5rem;
  }

  .offcanvas-body {
    padding-top: 3rem !important;
  }

  input, textarea {
    caret-color: var(--white) !important;
  }

  .ios-correction-margin {
    margin-bottom: 5.5rem !important;
  }
}
