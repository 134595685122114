body {
  --bg-1: rgb(9, 20, 33);
  --bg-1-rgb: 9, 20, 33;
  --bg-2: #0E1D2E;
  --purple: #1C395A;
  --input: #093D78;
  --grey: #A0A1B5;
  --white: #e9ecef;
  --blue: #0679FC;
  --blue-hover: #0665D1;
  --blue-2:#0078FF;
  --dark-navy:#043267;
  --accent-info: #21A9FF;
  --bg-navy-1: #002F64;
  --bg-navy-2: #0E1D2E;
  --disabled: #343E5B;
  --breadcrumb: #1C395A;
  --locked: #343E5B;
  --lang-en: #012F60;
  --lang-en3: #012F60;
  --lang-sp: #F08022;
  --lang-encfk: #FBE0CF;
  --secondary: #214875;
  --secondary-hover: #14345a;
  --bg-light: #f8f9fa;
  --bg-blue: #07468ea8;
  --danger: #FF4032;
  --danger-hover: #c23228;
  --card-border-color: #093D78;
  --course-bg-opacity: .75;
  --btn-pill-bg: #214875;
  --btn-pill-bg-hover: #214875;
  --btn-pill-font: #e9ecef;
  --hover-state: #0665D1;
  --pressed-state: #126d9a;
  --calendar-bg: #032B58;
  --calendar-card-bg: #159cff3b;
  --calendar-header-border: #135BAD70;
  --border-color: #10529c;
  --list-item-bg: #093D78;
  --highlighted: #093D78;
}

body.light-theme {
  --bg-1: rgb(255, 255, 255);
  --bg-1-rgb: 255, 255, 255;
  --bg-2: #EBEBEB;
  --purple: #E0E0E0;
  --input: #EAEAEA;
  --grey: #6B6B79;
  --white: #091421;
  --blue: #2c82ff;
  --blue-hover: #0679FC;
  --blue-2:#EBEBEB;;
  --dark-navy:#f8f8f8;
  --accent-info: #A0A1B5;
  --bg-navy-1: #FFFFFF;
  --bg-navy-2: #FFFFFF;
  --disabled: #8b8b8b;
  --breadcrumb: #97A8B7;
  --locked: #586794;
  --lang-en: #1358A3;
  --lang-en3: #1358A3;
  --lang-sp: #F8A056;
  --lang-encfk: #D8BBA9;
  --secondary: #A0B0BE;
  --secondary-hover: #879eb9;
  --bg-light: #c0c0c0;
  --bg-blue: #98c8ff6e;
  --danger: #FF4032;
  --danger-hover: #c23228;
  --card-border-color: #A0A1B5;
  --course-bg-opacity: .45;
  --btn-pill-bg: #ffffff;
  --btn-pill-bg-hover: #A0B0BE;
  --btn-pill-font: #2c82ff;
  --hover-state: #0679FC;
  --pressed-state: #98c1fe;
  --calendar-bg: #FFFFFF;
  --calendar-card-bg: #f2f3f3c1;
  --calendar-header-border: #EBEBEB;
  --border-color: #dee2e6;
  --list-item-bg: #F2F3F3;
  --highlighted: #e8f3ff;
}
